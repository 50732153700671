import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0043 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="雇用形態とは？働き方の種類や違いをわかりやすく解説！" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2023.01.27</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        国によるワーク・ライフ・バランスや働き方改革の施策や、新型コロナウイルス感染症の蔓延を受け、労働者の雇用形態は多様化の一途をたどっています。会社側でも、このような世の流れを受け、会社に適した働き方を検討していく必要があるでしょう。今回は、雇用形態とはそもそもどのような内容か、どのような種類が挙げられるのかを分かりやすく解説していきます。
      </p>
      <img src="/images/column/details/c0043.jpg" alt="キンクラコラム画像43" />
      <h1 id="toc1">雇用形態とは</h1>
      <p>
        <span>
          雇用形態とは、使用者である会社と従業員である社員が雇用契約を交わす際に取り決められる働き方の種類のことをいいます。
        </span>
        正社員や契約社員、パート・アルバイト、派遣労働者などの例を挙げると分かりやすいでしょうか。
        <br />
        具体的には、会社側がその社員に対して希望する働き方と、社員側が会社で働きたいと望む働き方が一致した場合に、雇用契約を交わす流れになりますが、この
        <span>「働き方」</span>のことを雇用形態と呼ぶことになります。
      </p>

      <h1 id="toc2">雇用形態の特徴</h1>
      <p>
        前述の通り、雇用形態は、「雇用契約」の締結時に取り決められる雇用の形態のことです。この「雇用契約」とは、社員がその会社で働くことを約束し、さらに会社が社員に対して働いた対価として報酬（給与）を与えることを約束した上で成り立つ契約のことです。つまり、雇用契約が成立した場合は、
        <span>社員は会社の指示に従いながら働くこと</span>になります。
        <br />
        仕事をする者が委託者の指示を受けない請負契約や委任契約などの「業務委託契約」とは異なる点に注意しましょう。
      </p>

      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}

      <h1 id="toc3">雇用契約の種類は主に二種類</h1>
      <p>
        雇用形態の種類には、さまざまな内容が挙げられます。この項目で順に見ていきましょう。なお、「雇用形態の特徴」で説明した通り、業務委託契約や請負契約などによる就労形態は雇用形態からは除外されることを前提として覚えておいてください。
        <br />
        <br />
        雇用形態には、大きく分けると<span>「正規雇用」と「非正規雇用」</span>
        の二種類に分類されます。
        <br />
        正規雇用とは、雇用期間の定めなく、フルタイムで働く者をいいます。いったん正規雇用者として雇われたら、転職や退職をする場合を除き、定年まで働く形態のことです。
        <br />
        一方、非正規雇用とは、正規雇用を除くすべての雇用形態のことで、無期雇用契約が原則となる正規雇用者と異なり、一定期間ごとに雇用契約を更新する「有期雇用契約」である点に特徴があります。
      </p>
      <h1 id="toc4">正規雇用者とは</h1>
      <p>
        無期雇用・フルタイム勤務が原則の正規雇用者は、<span>「正社員」</span>
        と呼ばれるケースが非常に多くみられます。正社員として契約をした場合、会社から提供される福利厚生や保険制度（労働保険・社会保険）が適用されます。雇用期間の定めがなく、会社の提示する所定労働時間いっぱい働く契約をしている場合ならば、転勤の有無などの他の条件を問わず、すべて正社員として扱われる点に注意が必要です。
      </p>
      <h1 id="toc4">非正規雇用者とは</h1>
      <p>非正規雇用者には、主に次の雇用形態の者が該当します。</p>
      <h2 id="toc4-1">①契約社員</h2>
      <p>
        契約社員とは、
        <span>一定の雇用契約期間ごとに雇用契約を更新しながら働く、</span>
        有期雇用者のことです。有期雇用の契約であれば働く時間帯がフルタイム勤務であっても短時間勤務であっても契約社員と扱われますが、一般的にはフルタイム勤務で、一定期間ごとに雇用契約を更新する者を契約社員とみなすケースが多くみられます。
      </p>
      <h2 id="toc4-2">②パートタイム労働者・アルバイト</h2>
      <p>
        パートタイム労働者は、「パート」や「パートタイマー」と略される者のことで、契約社員の場合と同じく有期雇用で、
        <span>正社員と比較すると勤務日数や勤務時間が短い者</span>
        のことをいいます。一方、アルバイトについては、働き方はパートタイム労働者とほぼ同じ形態の者をいいますが、学生や他の仕事をしながら働く者のことを指す場合が多いです。
        <br />
        パートタイム労働者とアルバイトには、会社が状況や事情に応じて呼び方を使い分けているケースが多く、法律上の明確な定義の違いがない点についても覚えておきましょう
      </p>
      <h2 id="toc4-3">③嘱託社員</h2>
      <p>
        <span>定年退職を迎えた人</span>
        が、本人の希望や豊富な経験・スキルを会社側に評価されるなどの理由で、定年前の企業に再雇用される際に適用される雇用形態です。定年後の労働者は、モチベーションや体力の変化により勤続意欲が短期間で変化するケースが多いことから、たいていは1年契約更新の有期雇用者として雇用契約が交わされています。
      </p>
      <h2 id="toc4-3">④派遣社員</h2>
      <p>
        派遣社員は、派遣元企業と雇用契約を交わし、派遣先の企業で働くという特殊な雇用形態
        <span>「派遣労働」</span>
        によって働く者をいいます。派遣社員には、無期雇用と有期雇用がありますが、派遣労働を行う社員のことは、雇用期間の定めの有無にかかわらずすべて派遣社員として扱われます。
      </p>
      <h1 id="toc5">まとめ</h1>
      <p>
        雇用形態の違いについてお分かりいただけましたでしょうか。正規雇用者、非正規雇用者にはそれぞれ異なる特徴があることから、会社の状況にあわせて、正規雇用者・非正規雇用者を使い分けることで、より効率良く生産性を高める効果がみられます。まずはそれぞれの雇用形態ごとに就労内容を検討し、人事・労務担当者と連携を取りながら採用活動を進めていく方法が有効でしょう。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0043
