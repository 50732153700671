import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor002 from '../../../components/molecules/columnAuthor002'
import ColumnDetailInner0043 from '../../../components/molecules/columnDetailInner0043'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import Conclusion001 from '../../../components/molecules/Conclusion001'
import Conclusion002 from '../../../components/molecules/Conclusion002'
import Conclusion003 from '../../../components/molecules/Conclusion003'
import Conclusion004 from '../../../components/molecules/Conclusion004'
import Conclusion005 from '../../../components/molecules/Conclusion005'
import Conclusion006 from '../../../components/molecules/Conclusion006'
import Conclusion007 from '../../../components/molecules/Conclusion007'
import Conclusion008 from '../../../components/molecules/Conclusion008'
import Conclusion009 from '../../../components/molecules/Conclusion009'
import UsefulFunctionLayout021 from '../../../components/molecules/usefulFunctionLayout021'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0043 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '雇用形態とは？働き方の種類や違いをわかりやすく解説！',
    },
  ]

  // 0~8ランダム数字を保存
  const [no, setNo] = useState(-1)
  useEffect(() => {
    setNo(Math.floor(Math.random() * 9))
  }, [])

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="雇用形態とは？働き方の種類や違いをわかりやすく解説！"
        description="雇用形態とは、使用者である会社と従業員である社員が雇用契約を交わす際に取り決められる働き方の種類のことをいいます。種類や違いを解説します。"
        ogUrl="https://kintaicloud.jp/column/details/C0043/"
        ogType="article"
        ogTitle="雇用形態とは？働き方の種類や違いをわかりやすく解説！"
        ogDescription="雇用形態とは、使用者である会社と従業員である社員が雇用契約を交わす際に取り決められる働き方の種類のことをいいます。種類や違いを解説します。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0043.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0043 no={no} />

        {/** ここに9パターンの広告設定する */}
        {no === 0 && <Conclusion001 />}
        {no === 1 && <Conclusion002 />}
        {no === 2 && <Conclusion003 />}
        {no === 3 && <Conclusion004 />}
        {no === 4 && <Conclusion005 />}
        {no === 5 && <Conclusion006 />}
        {no === 6 && <Conclusion007 />}
        {no === 7 && <Conclusion008 />}
        {no === 8 && <Conclusion009 />}

        {/** オーサ情報 */}
        <ColumnAuthor002 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout021 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事
                 <ColumnRelationCards0043 /> */}
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0043

export const pageQuery = graphql`
  query C0043 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
